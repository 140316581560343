<template>
  <div class="ams">
    <div class="asideAms">
        <el-menu
            default-active="1"
            class="el-menu-vertical-demo"
            background-color="#fff"
            text-color="#F0801B"
            active-text-color="#F0801B">
            <el-menu-item index="1" @click="changeTab(1)">
                 <i class="el-icon-takeaway-box"></i>
                <span slot="title">AMS草稿箱</span>
            </el-menu-item>
            <el-menu-item index="2" @click="changeTab(2)">
                <i class="el-icon-position"></i>
                <span slot="title">已发送AMS</span>
            </el-menu-item>
        </el-menu>
    </div>
    <div class="amsCont" v-show="tabShow == 1">
        <div class="draft" v-show="importFormShow">
            <div class="draft-head">
                <el-row :gutter="20">
                    <el-col :span="6" :offset="6">
                        <div class="grid-content">
                            <button @click="importForm"><i class="el-icon-folder-add"></i> 表格导入</button>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="grid-content">
                            <button @click="addOnline()"><i class="el-icon-edit-outline"></i> 在线新增AMS</button>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="draft-add" v-show="addOnlineShow">
                <div class="draft-add-tit">
                    <el-row>
                        <el-button type="success" @click="sendVGM()">保存并发送AMS</el-button>
                        <el-button type="success" @click="saveDraft()" plain>保存草稿</el-button>
                        <el-button type="info" plain @click="closeOnlineAdd()">关闭</el-button>
                    </el-row>
                </div>
                <div class="draft-add-main">
                    <el-form ref="amsData" :rules="rules" label-width="100px" :model="amsData">
                        <el-collapse v-model="activeNames">
                            <el-collapse-item title="基本信息" name="1">
                                <el-row :gutter="20">
                                     <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                          <el-form-item label="船公司" prop="carrier">
                                            <el-select v-model="amsData.carrier" placeholder="请选择">
                                                <el-option label="ACL" value="ACL"></el-option>
                                                <el-option label="ANL" value="ANL"></el-option>
                                            </el-select>
                                        </el-form-item>
                                     </el-col>
                                     <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                          <el-form-item label="船东提单号" prop="carrier">
                                            <el-input placeholder="请输入船东提单号" v-model="amsData.carrier" clearable></el-input>
                                        </el-form-item>
                                     </el-col>
                                     <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                          <el-form-item label="船名" prop="carrier">
                                            <el-input placeholder="请输入船名" v-model="amsData.carrier" clearable></el-input>
                                        </el-form-item>
                                     </el-col>
                                     <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                        <el-form-item label="航次" prop="carrier">
                                            <el-input placeholder="请输入航次" v-model="amsData.carrier" clearable></el-input>
                                        </el-form-item>
                                     </el-col>
                                    <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                          <el-form-item label="运输方式" prop="carrier">
                                            <el-select v-model="amsData.carrier" placeholder="请选择">
                                                <el-option label="Import-非FROB" value="ACL"></el-option>
                                                <el-option label="FROB-不下船过境美国" value="ANL"></el-option>
                                            </el-select>
                                        </el-form-item>
                                     </el-col>
                                      <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                        <el-form-item label="发货地" prop="carrier">
                                            <el-input placeholder="请输入船东接货地" v-model="amsData.carrier" clearable></el-input>
                                        </el-form-item>
                                     </el-col>
                                     <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                        <el-form-item label="装货港" prop="carrier">
                                            <el-input placeholder="去美国的最后一程船装货港口" v-model="amsData.carrier" clearable></el-input>
                                        </el-form-item>
                                     </el-col>
                                     <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                        <el-form-item label="非美港口" prop="carrier">
                                            <el-input placeholder="进美国前最后一个挂靠/中转港口" v-model="amsData.carrier" clearable></el-input>
                                        </el-form-item>
                                     </el-col>
                                     <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                        <el-form-item label="卸货港" prop="carrier">
                                            <el-input placeholder="美国卸货或挂靠的第一个港口" v-model="amsData.carrier" clearable></el-input>
                                        </el-form-item>
                                     </el-col>
                                     <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                        <el-form-item label="预计出发" prop="carrier">
                                            <el-date-picker
                                                v-model="amsData.carrier"
                                                type="date"
                                                placeholder="选择日期">
                                            </el-date-picker>
                                        </el-form-item>
                                     </el-col>
                                     <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
                                        <el-form-item label="预计到达" prop="carrier">
                                            <el-date-picker
                                                v-model="amsData.carrier"
                                                type="date"
                                                placeholder="选择日期">
                                            </el-date-picker>
                                        </el-form-item>
                                     </el-col>
                                </el-row>
                            </el-collapse-item>
                            <el-collapse-item title="House单信息" name="2" class="house">
                                <div class="houseMain">
                                    <div class="houseList">
                                        <ul>
                                            <li>新建House单<span>删除</span></li>
                                            <li>新建House单<span>删除</span></li>
                                            <li class="addHouse"><i class="el-icon-circle-plus"></i> 添加House单</li>
                                        </ul>
                                    </div>
                                    <div class="housefrom">
                                        <div class="HBL">
                                            <el-row>
                                                <el-col :span="6">
                                                    <el-form-item label="HBL No" prop="carrier">
                                                        <el-input placeholder="请输入货代提单号" v-model="amsData.carrier" clearable></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </div>
                                        <div class="titDiv">收发通信息 
                                            <span>(*请务必填写真实信息，否则，被目的港退回、产生罚款等后果由用户自行承担)</span>
                                        </div>
                                        <div class="houseItem">
                                            <el-row :gutter="10">
                                                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">  
                                                    <el-form-item label="发货人" prop="shipper_name">
                                                        <el-input v-model="amsData.shipper_name"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="地址" prop="shipper_addr">
                                                        <el-input type="textarea" v-model="amsData.shipper_addr"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="电话" prop="shipper_tel">
                                                        <el-input v-model="amsData.shipper_tel"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">  
                                                    <el-form-item label="收货人" prop="consignee_name">
                                                        <el-input v-model="amsData.consignee_name"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="地址" prop="consignee_addr">
                                                        <el-input type="textarea" v-model="amsData.consignee_addr"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="电话" prop="consignee_tel">
                                                        <el-input v-model="amsData.consignee_tel"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                                <el-col :xs="24" :sm="12" :md="12" :lg="8" :xl="8">  
                                                    <el-form-item label="通知人" prop="notifier_name">
                                                        <el-input v-model="amsData.notifier_name"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="地址" prop="notifier_addr">
                                                        <el-input type="textarea" v-model="amsData.notifier_addr"></el-input>
                                                    </el-form-item>
                                                    <el-form-item label="电话" prop="notifier_tel">
                                                        <el-input v-model="amsData.notifier_tel"></el-input>
                                                    </el-form-item>
                                                </el-col>
                                            </el-row>
                                        </div>
                                        <div class="titDiv">集装箱信息</div>
                                        <div class="houseItem">
                                            <div class="houseItemTit">
                                                <el-button type="warning" @click="cargosItemVisible = true" size="small">添加集装箱信息</el-button>
                                                <el-dialog
                                                    title="货物信息"
                                                    :visible.sync="cargosItemVisible"
                                                    width="50%">
                                                    <span>
                                                        <el-form :model="cargosItem" :rules="cargosItemRules" ref="cargosItem" label-width="100px" class="demo-ruleForm">
                                                            <el-row :gutter="10">
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="货物标识" prop="cargo_type">
                                                                        <el-select v-model="cargosItem.cargo_type">
                                                                            <el-option label="普通" value="S"></el-option>
                                                                            <el-option label="冷冻" value="R"></el-option>
                                                                            <el-option label="危险" value="D"></el-option>
                                                                            <el-option label="非标" value="O"></el-option>
                                                                        </el-select>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="货物代码">
                                                                        <el-input v-model="cargosItem.cargo_code"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="货物数量" prop="cargo_num">
                                                                        <el-input v-model="cargosItem.cargo_num"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-row>
                                                            <el-row :gutter="10">
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="货物毛重" prop="cargo_weight">
                                                                        <el-input v-model="cargosItem.cargo_weight"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="货物体积" prop="cargo_volume">
                                                                        <el-input v-model="cargosItem.cargo_volume"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="包装类型" prop="cargo_pack">
                                                                        <el-select v-model="cargosItem.cargo_pack">
                                                                            <el-option label="BAGS" value="BAGS"></el-option>
                                                                            <el-option label="BALES" value="BALES"></el-option>
                                                                            <el-option label="BARES" value="BARES"></el-option>
                                                                            <el-option label="BUNDLES" value="BUNDLES"></el-option>
                                                                            <el-option label="BOTTLES" value="BOTTLES"></el-option>
                                                                            <el-option label="BOXS" value="BOXS"></el-option>
                                                                            <el-option label="BULKS" value="BULKS"></el-option>
                                                                            <el-option label="CASES" value="CASES"></el-option>
                                                                            <el-option label="CASKS" value="CASKS"></el-option>
                                                                            <el-option label="CANS" value="CANS"></el-option>
                                                                            <el-option label="COILS" value="COILS"></el-option>
                                                                            <el-option label="CRATES" value="CRATES"></el-option>
                                                                            <el-option label="CYLINDERS" value="CYLINDERS"></el-option>
                                                                            <el-option label="CARTONS" value="CARTONS"></el-option>
                                                                            <el-option label="BARES" value="BARES"></el-option>
                                                                            <el-option label="PANELS" value="PANELS"></el-option>
                                                                            <el-option label="PACKAGES" value="PACKAGES"></el-option>
                                                                            <el-option label="PACKS" value="PACKS"></el-option>
                                                                            <el-option label="PALLETS" value="PALLETS"></el-option>
                                                                            <el-option label="FRAMES" value="FRAMES"></el-option>
                                                                            <el-option label="RACKS" value="RACKS"></el-option>
                                                                            <el-option label="ROLLS" value="ROLLS"></el-option>
                                                                            <el-option label="ISOTANK" value="ISOTANK"></el-option>
                                                                            <el-option label="IBC" value="IBC"></el-option>
                                                                            <el-option label="SACKS" value="SACKS"></el-option>
                                                                            <el-option label="SET" value="SET"></el-option>
                                                                            <el-option label="SHEETS" value="SHEETS"></el-option>
                                                                            <el-option label="TANK" value="TANK"></el-option>
                                                                            <el-option label="UNITS" value="UNITS"></el-option>
                                                                            <el-option label="WOODEN CASES" value="WOODEN CASES"></el-option>
                                                                        </el-select>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-row>
                                                            <el-row :gutter="10">
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="货物品名" prop="cargo_name">
                                                                        <el-input type="textarea" v-model="cargosItem.cargo_name"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="HSCode">
                                                                        <el-input v-model="cargosItem.hscode"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="唛头" prop="marks">
                                                                        <el-input type="textarea" v-model="cargosItem.marks"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-row>
                                                            <el-row :gutter="10">
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="包装皮重" prop="packaging_tare">
                                                                        <el-input v-model="cargosItem.packaging_tare"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="防疫代码">
                                                                        <el-input v-model="cargosItem.quarantine_code"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item label="防疫名称">
                                                                        <el-input v-model="cargosItem.quarantine_name"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-row>
                                                            <el-row :gutter="10">
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item v-show="cargosItem.cargo_type == 'D'" label="危险品分类" prop="danger_class" :rules="cargosItem.cargo_type == 'D' ? cargosItemRules.danger_class : []">
                                                                        <el-input v-model="cargosItem.danger_class"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item v-show="cargosItem.cargo_type == 'D'" label="危险品编号" prop="danger_no" :rules="cargosItem.cargo_type == 'D' ? cargosItemRules.danger_no : []">
                                                                        <el-input v-model="cargosItem.danger_no"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item v-show="cargosItem.cargo_type == 'D'" label="危险品页号" prop="danger_page" :rules="cargosItem.cargo_type == 'D' ? cargosItemRules.danger_page : []">
                                                                        <el-input v-model="cargosItem.danger_page"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-row>
                                                            <el-row :gutter="10">
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item v-show="cargosItem.cargo_type == 'D'" label="危险品闪点" prop="danger_flashpoint" :rules="cargosItem.cargo_type == 'D' ? cargosItemRules.danger_flashpoint : []">
                                                                        <el-input v-model="cargosItem.danger_flashpoint"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item v-show="cargosItem.cargo_type == 'D'" label="包装类别" prop="packing_group">
                                                                        <el-input v-model="cargosItem.packing_group"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                                <el-col :xs="24" :sm="8" :md="8" :lg="8" :xl="8">
                                                                    <el-form-item v-show="cargosItem.cargo_type == 'D'" label="紧急联系人" prop="danger_phone">
                                                                        <el-input v-model="cargosItem.danger_phone"></el-input>
                                                                    </el-form-item>
                                                                </el-col>
                                                            </el-row>
                                                        </el-form>
                                                    </span>
                                                    <span slot="footer" class="dialog-footer">
                                                        <el-button @click="cargosItemVisible = false">取 消</el-button>
                                                        <!-- <el-button type="primary" v-show="!saveCargos" @click="sureCargosItem()">确 定</el-button>
                                                        <el-button type="primary" v-show="saveCargos" @click="saveCargosItem()">保 存</el-button> -->
                                                    </span>
                                                </el-dialog>
                                            </div>
                                            <el-table
                                                :data="amsData.cargos"
                                                border
                                                style="width: 100%">
                                                <el-table-column
                                                    prop="cargo_type"
                                                    label="箱号"
                                                    >
                                                </el-table-column>
                                                <el-table-column
                                                    prop="cargo_code"
                                                    label="封号"
                                                    width="">
                                                </el-table-column>
                                                <el-table-column
                                                    prop="cargo_num"
                                                    label="箱型">
                                                </el-table-column>
                                                <el-table-column
                                                    prop="cargo_weight"
                                                    label="件数">
                                                </el-table-column>
                                                <el-table-column
                                                    prop="cargo_volume"
                                                    label="包装单位">
                                                </el-table-column>
                                                <el-table-column
                                                    prop="cargo_pack"
                                                    label="毛重">
                                                </el-table-column>
                                                <el-table-column
                                                    prop="packaging_tare"
                                                    label="体积">
                                                </el-table-column>
                                                <el-table-column
                                                    label="操作"
                                                    >
                                                    <!-- <template slot-scope="scope">
                                                        <el-button
                                                            size="mini"
                                                            type="primary"
                                                            @click="auitCargos(scope.$index, scope.row)"
                                                            >编辑</el-button>
                                                        <el-button
                                                            size="mini"
                                                            type="danger"
                                                            @click="del(scope.$index, amsData.cargos)"
                                                            >删除</el-button>
                                                    </template> -->
                                                </el-table-column>
                                                <div slot="empty" class="emptyBg">
                                                    <img src="../../assets/empty.png" alt="">
                                                    <p>没有记录哦~</p>
                                                </div>
                                            </el-table>
                                        </div>
                                    </div>
                                </div>
                            </el-collapse-item>
                        </el-collapse>
                    </el-form>
                </div>
            </div>
    </div>
    <div class="amsSent" v-show="tabShow == 2">
        <div class="amsSent-cont">
            <el-table
                ref="multipleTable"
                :border="true"
                :data="SentAms"
                tooltip-effect="dark"
                style="width: 100%">
                <el-table-column
                    prop="blno"
                    label="HBL"
                    width="150">
                </el-table-column>
                <el-table-column
                    label="MBL"
                    width="100"
                    >
                        <template slot-scope="scope">
                        <span>{{scope.row.port | portFilter}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="vessel"
                    label="AMS B/L NO"
                    >
                </el-table-column>
                <el-table-column
                    prop="voyage"
                    label="申报状态"
                    >
                </el-table-column>
                <el-table-column
                    prop="terminal"
                    label="匹配状态"
                    >
                    <template slot-scope="scope">
                        <span>{{scope.row.terminal | matouFilter}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="carrier"
                    label="最新通知"
                    width="100"
                    >
                </el-table-column>
                <el-table-column
                    label="船公司"
                    width="125"
                    >
                    <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                            {{item.ctn_no}}
                        </p>
                    </template>
                </el-table-column>
                    <el-table-column
                    label="发货人姓名"
                    width="100"
                    >
                    <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                            {{item.ctn_type_size}}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    label="最新操作人"
                    width="130">
                        <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                            {{item.vgm_gross_weight}}
                        </p>
                    </template>
                </el-table-column>
                    <el-table-column
                    label="最新操作"
                    width="80"
                    >
                    <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                            {{item.vgm_method}}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="shipper_vgm_person_sig"
                    label="船名"
                    width="100"
                    >
                        <template slot-scope="scope">
                        <p v-for="(item,index) in scope.row.container_detais" :key="index">
                            {{item.shipper_vgm_person_sig}}
                        </p>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="update_time"
                    label="航次"
                    width="165"
                    >
                </el-table-column>
                <el-table-column
                    prop="update_time"
                    label="装货港"
                    width="165"
                    >
                </el-table-column>
                <div slot="empty" class="emptyBg">
                    <img src="../../assets/empty.png" alt="">
                    <p>没有记录哦~</p>
                </div>
            </el-table>
            <div class="ams-page">
                <el-pagination
                    background
                    @size-change="handleSizeChange2"
                    @current-change="handleCurrentChange2"
                    :current-page="currentPage2"
                    :page-sizes="[10, 20, 30, 40, 50, 100]"
                    :page-size="10"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="count2">
                </el-pagination>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
    import AMS from './AMS.js'
    export default AMS
</script>

<style>
    @import './AMS.css';
</style>