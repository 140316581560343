import AMS from './AMS.vue'
export default {
    components: {
        AMS
    },
    data() {
        return {
            tabShow: 1,
            importFormShow: false,
            addOnlineShow: true,
            activeNames: ['1', '2'],
            amsData: {
                carrier: '',
                cargos:[]

            },
            rules: {

            },
            cargosItemVisible: false,
            cargosItem: {},
            cargosItemRules:{},
            SentAms: [],// 已发送
            page2:1,
            per_page2:10,
            currentPage2:1,
            count2:0,
        }
    },
    methods: {
        changeTab(val) {
            this.tabShow = val
            if(val == 1){
            this.importFormShow = true
            this.addOnlineShow = false
            // this.searchDraft()
            }else if(val == 2){
            // this.sentSearch()
            }
        },
        // 点击表格导入
        importForm(){
            this.DraftPop = true
            this.importFormShow = true
            this.addOnlineShow = false
        },
        // 点击在线新增AMS
        addOnline() {
            this.importFormShow = false
            this.addOnlineShow = true
        },


        handleSizeChange2(val) {
            console.log(`每页 ${val} 条`);
            this.per_page2 = val
            // this.sentSearch(this.page2,this.per_page2)
          },
          handleCurrentChange2(val) {
            console.log(`当前页: ${val}`);
            this.page2 = val
            // this.sentSearch(this.page2,this.per_page2)
          },
    }
}